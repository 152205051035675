.cardFront {
  box-sizing: border-box;
  padding: 2rem;
  width: 447px;
  height: 245px;
  background: url("../assets/img/bg-card-front.png");
  background-repeat: no-repeat;
  box-shadow: 0 0 1rem rgb(0, 0, 0, 0.2);
  border-radius: 10px;
  background-size: cover;
}

.cardNumber {
  color: #fff;
  letter-spacing: 0.3rem;
  margin-top: 4rem;
  font-weight: 600;
  font-size: x-large;
}

.nameCad {
  display: flex;
  justify-content: space-between;
  text-transform: uppercase;
  letter-spacing: 0.1rem;
  color: #fff;
  font-weight: 600;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.nameCad > p {
  margin-top: 0;
  display: inline;
}

.nameCad > p:first-child {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

@media only screen and (max-width: 1000px) {
  .cardFront {
    width: calc(160px * var(--card-ratio));
    height: 160px;
    position: relative;
    padding: 1.5rem;
    z-index: 2;
    left: -1.5rem;
    top: 7.6rem;
  }

  .nameCad > p {
    font-size: small;
  }

  .cardNumber {
    margin-top: 2rem;
    margin-bottom: 0.5rem;
    font-size: large;
  }

  .cardLogo {
    height: 2rem;
    width: auto;
  }
}
