.cardBack {
  box-sizing: border-box;
  width: 447px;
  height: 245px;
  background: url("../assets/img/bg-card-back.png");
  background-repeat: no-repeat;
  box-shadow: 0 0 1rem rgb(0, 0, 0, 0.2);
  border-radius: 10px;
  background-size: cover;
}

.cvv {
  position: relative;
  top: 6.85rem;
  left: 22.6rem;
  color: #fff;
  width: fit-content;
}

@media only screen and (max-width: 1000px) {
  .cardBack {
    width: calc(160px * var(--card-ratio));
    height: 160px;
    position: relative;
    z-index: 1;
    right: -2rem;
    top: -9rem;
  }
  .cvv {
    top: 4.4rem;
    left: 14.5rem;
    font-size: small;
  }
}
