.app {
  background: url("./assets/img/bg-main-desktop.png");
  height: 100%;
  background-repeat: no-repeat;
  background-size: contain;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10vw;
}

.cardContainer {
  width: fit-content;
  padding: 1rem;
  left: 10rem;
  top: 18%;
}

.cardContainer > div:last-child {
  margin-left: 5rem;
  margin-top: 2rem;
}

footer {
  overflow-x: hidden;
  position: fixed;
  left: 0;
  bottom: 0;
  color: var(--light-cyan);
  width: 100%;
  text-align: center;
  background-color: rgb(255, 255, 255, 0.8);
}
footer > p {
  margin: 0.4rem 0;
  color: var(--dark-grayish-violet);
}

footer > p > a {
  text-decoration: none;
  color: var(--very-dark-violet);
  margin: 0 0.2rem;
}

@media only screen and (max-width: 1000px) {
  .app {
    flex-flow: column;
    background: url("./assets/img/bg-main-mobile.png");
    background-repeat: no-repeat;
    background-size: contain;
    justify-content: start;
  }

  .cardContainer {
    height: 200px;
  }

  .cardContainer > div:last-child {
    margin-left: 0;
    margin-top: 0;
  }
}
