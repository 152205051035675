.inputWrapper,
.inputWrapper[error="false"] {
  width: 100%;
  height: 2.5rem;
  margin-top: 0.5rem;
  border: solid 1px transparent;
  border-radius: 10px;
  background-image: linear-gradient(white, white),
    radial-gradient(circle at top left, hsl(249, 99%, 64%), hsl(278, 94%, 30%));
  background-origin: border-box;
  background-clip: padding-box, border-box;
}

.inputWrapper[error="true"] {
  background-image: linear-gradient(white, white),
    radial-gradient(circle at top left, rgb(255, 90, 90), rgb(255, 90, 90));
}

.inputComponent {
  margin-top: 1.5rem;
}

input {
  height: 95%;
  width: 95%;
  border: none;
  font-family: "Space Grotesk", sans-serif;
  margin-left: 0.5rem;
  letter-spacing: 0.1rem;
  background-color: transparent;
}

input:focus {
  outline: none;
}

label {
  font-size: smaller;
  font-weight: 900;
}

input,
label {
  text-transform: uppercase;
  display: block;
}
