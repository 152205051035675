.form {
  width: 24rem;
  font-size: smaller;
  letter-spacing: 0.1rem;
  padding: 1rem;
  background-color: #fff;
}

.cardDetails {
  display: flex;
}

.confirmButton {
  color: #fff;
  background-color: var(--very-dark-violet);
  border: none;
  width: 100%;
  height: 2.5rem;
  margin-top: 1.5rem;
  border-radius: 8px;
  font-family: "Space Grotesk", sans-serif;
  font-size: large;
  font-weight: 800;
  box-shadow: 0 0 1rem rgb(0, 0, 0, 0.2);
  cursor: pointer;
}

.confirmButton:disabled {
  background-color: var(--dark-grayish-violet);
  cursor: default;
  box-shadow: none;
}
.errorText {
  color: rgb(255, 90, 90);
}

@media only screen and (max-width: 1000px) {
  .form {
    width: 90%;
    padding: 4rem 5%;
  }
}
