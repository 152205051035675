.confirmation {
  text-align: center;
  width: 24rem;
  background-color: #fff;
}
.confirmation > h1 {
  letter-spacing: 0.2em;
}

.confirmation > p {
  color: var(--dark-grayish-violet);
}

.confirmation > img {
  margin: 0 auto;
}

@media only screen and (max-width: 1000px) {
  .confirmation {
    width: 60%;
    padding: 4rem 20%;
  }
}
