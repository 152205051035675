@import url("https://fonts.googleapis.com/css?family=Space+Grotesk");

:root {
  --light-grayish-violet: hsl(270, 3%, 87%);
  --dark-grayish-violet: hsl(279, 6%, 55%);
  --very-dark-violet: hsl(278, 68%, 11%);
  --input-linear-gradient: linear-gradient(
    hsl(249, 99%, 64%),
    hsl(278, 94%, 30%)
  );
  --card-ratio: 1.824489796;
}

body {
  margin: 0;
  font-family: "Space Grotesk", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100vh;
}

#root {
  height: 100%;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.flex {
  display: flex;
}
